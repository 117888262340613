.pageWrapper {
  display: flex;
  width: 100%;
}

.contentWrapper {
  width: 100%;
  display: flex;
}

@media only screen and (max-width: 900px) {

  .contentWrapper {
    width: 110%;
    margin-left: -50px;
  }

}

.outerWrapper {
  width: 100%;
}

.tileWrapper {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.tileWrapper div {
  margin: auto;
  padding: 10px;
}

.tileWrapper p.instructions{
  font-size: 2rem;
}
